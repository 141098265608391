import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [octaneHigh, setOctaneHigh] = useState('');
  const [octaneLow, setOctaneLow] = useState('');
  const [gallonsLow, setGallonsLow] = useState('');
  const [octaneMixture, setOctaneMixture] = useState(null);
  const [percentageHigh, setPercentageHigh] = useState(null);
  const [percentageLow, setPercentageLow] = useState(null);
  const [totalGallons, setTotalGallons] = useState(null);
  const [gallonsDifference, setGallonsDifference] = useState(null);
  const [gallonsHigh, setGallonsHigh] = useState(null);

  useEffect(() => {
    if (gallonsLow && octaneLow && octaneHigh) {
      const lowGallons = parseFloat(gallonsLow);
      const fixedTotalGallons = 17.6;
      const highGallons = fixedTotalGallons - lowGallons;

      setGallonsHigh(highGallons.toFixed(1));

      const totalGallonsCalc = highGallons + lowGallons;
      const percentageHighCalc = (highGallons / totalGallonsCalc) * 100;
      const percentageLowCalc = (lowGallons / totalGallonsCalc) * 100;

      setPercentageHigh(percentageHighCalc.toFixed(1));
      setPercentageLow(percentageLowCalc.toFixed(1));

      const mixture = (percentageHighCalc / 100 * parseFloat(octaneHigh)) + (percentageLowCalc / 100 * parseFloat(octaneLow));
      setOctaneMixture(mixture);

      setTotalGallons(totalGallonsCalc);
      setGallonsDifference((totalGallonsCalc - fixedTotalGallons).toFixed(1));
    }
  }, [gallonsLow, octaneLow, octaneHigh]);

  const getDifferenceColor = (difference) => {
    if (difference === '0.0') return 'green';
    if (parseFloat(difference) < 0) return 'red';
    return 'blue';
  };

  const clearValues = () => {
    setOctaneHigh('');
    setOctaneLow('');
    setGallonsLow('');
    setOctaneMixture(null);
    setPercentageHigh(null);
    setPercentageLow(null);
    setTotalGallons(null);
    setGallonsDifference(null);
    setGallonsHigh(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Octane Mixture Calculator</h1>
        <div className="calculator">
          <div className="form-container">
            <div className="input-group">
              <label>
                Octane Low:
                <input
                  type="number"
                  value={octaneLow}
                  onChange={(e) => setOctaneLow(e.target.value)}
                  step="0.01"
                  className="no-arrows"
                  required
                  style={{ width: '100px' }}
                />
              </label>
            </div>
            <div className="input-group">
              <label>
                Octane Low Gal.:
                <input
                  type="number"
                  value={gallonsLow}
                  onChange={(e) => setGallonsLow(e.target.value)}
                  step="0.01"
                  className="no-arrows"
                  required
                  style={{ width: '100px' }}
                />
              </label>
            </div>
            <div className="input-group">
              <label>
                Octane High:
                <input
                  type="number"
                  value={octaneHigh}
                  onChange={(e) => setOctaneHigh(e.target.value)}
                  step="0.01"
                  className="no-arrows"
                  required
                  style={{ width: '100px' }}
                />
              </label>
            </div>
            {gallonsHigh !== null && (
              <div className="input-group">
                <label>
                  Octane High Gal.:
                  <input
                    type="number"
                    value={gallonsHigh}
                    readOnly
                    className="no-arrows"
                    style={{ width: '100px' }}
                  />
                </label>
              </div>
            )}
            <button onClick={clearValues} style={{ marginTop: '1em', width: '100%' }}>Clear Values</button>
          </div>
          <div className="output-container">
            {percentageHigh !== null && (
              <div className="output-box">
                <label>% Mixture of Octane High {octaneHigh}:</label>
                <input
                  type="text"
                  value={`${percentageHigh}%`}
                  readOnly
                  className="no-arrows"
                  style={{ width: '100px', backgroundColor: 'lightgrey' }}
                />
                <label>% Mixture of Octane Low {octaneLow}:</label>
                <input
                  type="text"
                  value={`${percentageLow}%`}
                  readOnly
                  className="no-arrows"
                  style={{ width: '100px', backgroundColor: 'lightgrey' }}
                />
              </div>
            )}
            {totalGallons !== null && (
              <div className="output-box">
                <label>Total Gallons:</label>
                <input
                  type="text"
                  value={totalGallons.toFixed(1)}
                  readOnly
                  className="no-arrows"
                  style={{ width: '100px', backgroundColor: 'lightgrey' }}
                />
                <label style={{ color: getDifferenceColor(gallonsDifference) }}>
                  Difference from 17.6 Gallons:
                </label>
                <input
                  type="text"
                  value={`${gallonsDifference} gallons`}
                  readOnly
                  className="no-arrows"
                  style={{
                    width: '100px',
                    backgroundColor: 'lightgrey',
                    color: getDifferenceColor(gallonsDifference),
                  }}
                />
              </div>
            )}
            {octaneMixture !== null && (
              <div className="output-box">
                <label>The octane of the resulting mixture is:</label>
                <input
                  type="text"
                  value={octaneMixture.toFixed(2)}
                  readOnly
                  className="no-arrows"
                  style={{ width: '100px', backgroundColor: 'lightgrey' }}
                />
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;